export const DISPATCH_ERROR = "DISPATCH_ERROR";
export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

export const CLEAR_LOGIN_SUCCESS = "CLEAR_LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const POST_REFERRAL_SUCCESS = "POST_REFERRAL_SUCCESS";
export const POST_REFERRAL_FAIL = "POST_REFERRAL_FAIL";
export const POST_REFERRAL_REQUEST = "POST_REFERRAL_REQUEST";
export const POST_REFERRAL_LOADING = "POST_REFERRAL_LOADING";
export const CLEAR_POST_REFERRAL_SUCCESS = "CLEAR_POST_REFERRAL_SUCCESS";

export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAIL = "GET_REFERRAL_FAIL";
export const GET_REFERRAL_REQUEST = "GET_REFERRAL_REQUEST";
export const GET_REFERRAL_LOADING = "GET_REFERRAL_LOADING";
export const CLEAR_GET_REFERRAL_SUCCESS = "CLEAR_GET_REFERRAL_SUCCESS";

export const POST_CREATE_FARM_SUCCESS = "POST_CREATE_FARM_SUCCESS";
export const POST_CREATE_FARM_FAIL = "POST_CREATE_FARM_FAIL";
export const POST_CREATE_FARM_REQUEST = "POST_CREATE_FARM_REQUEST";
export const POST_CREATE_FARM_LOADING = "POST_CREATE_FARM_LOADING";
export const CLEAR_POST_CREATE_FARM_SUCCESS = "CLEAR_POST_CREATE_FARM_SUCCESS";

export const POST_CREATE_INVESTMENT_SUCCESS = "POST_CREATE_INVESTMENT_SUCCESS";
export const POST_CREATE_INVESTMENT_FAIL = "POST_CREATE_INVESTMENT_FAIL";
export const POST_CREATE_INVESTMENT_REQUEST = "POST_CREATE_INVESTMENT_REQUEST";
export const POST_CREATE_INVESTMENT_LOADING = "POST_CREATE_INVESTMENT_LOADING";
export const CLEAR_POST_CREATE_INVESTMENT_SUCCESS =
  "CLEAR_POST_CREATE_INVESTMENT_SUCCESS";

export const GET_FARMS_SUCCESS = "GET_FARMS_SUCCESS";
export const GET_FARMS_FAIL = "GET_FARMS_FAIL";
export const GET_FARMS_REQUEST = "GET_FARMS_REQUEST";
export const GET_FARMS_LOADING = "GET_FARMS_LOADING";

export const GET_FARM_SUCCESS = "GET_FARM_SUCCESS";
export const GET_FARM_FAIL = "GET_FARM_FAIL";
export const GET_FARM_REQUEST = "GET_FARM_REQUEST";
export const GET_FARM_LOADING = "GET_FARM_LOADING";

export const GET_USER_INVESTMENTS_SUCCESS = "GET_USER_INVESTMENTS_SUCCESS";
export const GET_USER_INVESTMENTS_FAIL = "GET_USER_INVESTMENTS_FAIL";
export const GET_USER_INVESTMENTS_REQUEST = "GET_USER_INVESTMENTS_REQUEST";
export const GET_USER_INVESTMENTS_LOADING = "GET_USER_INVESTMENTS_LOADING";

export const GET_INVESTMENTS_SUCCESS = "GET_INVESTMENTS_SUCCESS";
export const GET_INVESTMENTS_FAIL = "GET_INVESTMENTS_FAIL";
export const GET_INVESTMENTS_REQUEST = "GET_INVESTMENTS_REQUEST";
export const GET_INVESTMENTS_LOADING = "GET_INVESTMENTS_LOADING";

export const GET_INVESTMENT_SUCCESS = "GET_INVESTMENT_SUCCESS";
export const GET_INVESTMENT_FAIL = "GET_INVESTMENT_FAIL";
export const GET_INVESTMENT_REQUEST = "GET_INVESTMENT_REQUEST";
export const GET_INVESTMENT_LOADING = "GET_INVESTMENT_LOADING";

export const PUT_INVESTMENT_SUCCESS = "PUT_INVESTMENT_SUCCESS";
export const PUT_INVESTMENT_FAIL = "PUT_INVESTMENT_FAIL";
export const PUT_INVESTMENT_REQUEST = "PUT_INVESTMENT_REQUEST";
export const PUT_INVESTMENT_LOADING = "PUT_INVESTMENT_LOADING";

export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const GOOGLE_AUTH_REQUEST = "GOOGLE_AUTH_REQUEST";
export const GOOGLE_AUTH_LOADING = "GOOGLE_AUTH_LOADING";
